<template>
  <div>
    <router-view />
    <div>
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </div>
    <div slot="footer">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT  © {{ new Date().getFullYear() }}
          <span class="text-success">GoTech SA. </span>
          <span class="d-none d-sm-inline-block"> All rights Reserved</span>
        </span>
        <span class="float-md-right d-none d-md-block">We just
          <feather-icon
            icon="HeartIcon"
            size="21"
            class="text-danger stroke-current"
          /> what we do!
        </span>
      </p>
    </div>
  </div>
    
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AppBreadcrumb
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
